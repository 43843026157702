<template>
  <div style="width:100%">
      <el-button type="primary" @click="editDetail('', 'add')" size="small">新增学校</el-button>
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="类型" >
          <ChooseSchoolType v-model="form.schoolTypeId" :showAll="true"  style="width:120px"></ChooseSchoolType>
        </el-form-item>
        <el-form-item label="状态" >
          <el-select v-model="form.status" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="已合作" value="1"></el-option>
              <el-option label="未合作" value="0"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="地区" >
            <ChooseArea ref="chooseArea" v-model="areaValue"  @changeArea="changeArea" :checkStrictly="true" :selectedLevel="4" style="width:250px" ></ChooseArea>
          </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" stripe>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip width="200">
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName}}</span><br />
          <span >{{scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span class="table-text" @click="editDetail(scope.row.id, 'show')">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" show-overflow-tooltip prop="schoolTypeName" width="150"></el-table-column>    
      <el-table-column label="业务员" align="center" prop="salesUserName" width="120"></el-table-column> 
      <el-table-column label="配送员" align="center" prop="deliverUserName" width="120"></el-table-column> 
      <el-table-column label="状态" align="center" show-overflow-tooltip width="150">
        <template slot-scope="scope">
          <span >{{scope.row.status==1?'已合作':'未合作'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" align="center" width="180"></el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.id,'edit')">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>

  </div>
</template>

<script>
import SchoolForm from './SchoolForm'
import ChooseArea from '@/components/ChooseArea/index.vue'
import ChooseSchoolType from '@/components/ChooseSchoolType/index.vue'
import { Confirm } from '@/utils/common.js'
import { deleteSchoolInfo, getSchoolList } from '@/api/index.js'
export default {
  name: 'schoolList',
  components: {  SchoolForm,ChooseArea,ChooseSchoolType},
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      isFastAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        schoolTypeId:'',
        status:''
      },
      list: [],
      total: 0,
      editType: '',
      areaValue:['','','',''],
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    goSetGrade(schoolTypeInfo){
      this.$emit('changeShowType', 'grade',schoolTypeInfo)
    },
    editDetail(id, editType) {
      this.$emit('changeShowType', editType,id)
    },
    getList() {
      getSchoolList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该学校吗?', () => {
        deleteSchoolInfo({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
     changeArea(selectedData){
      this.form.proId = selectedData.proId;
      this.form.cityId=selectedData.cityId;
      this.form.regId=selectedData.regId;
      this.form.streetId=selectedData.streetId;
    },
  }
}
</script>

<style>
</style>