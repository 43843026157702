<template>
  <div>
    <div class="form-title">{{editType | typeName}}学校信息</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto" >
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基础信息" name="1"> 
        <div style="text-align:center">
          <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称不可为空', trigger: 'blur' }]">
            <el-input v-model="form.name" placeholder="请输入名称" style="width:500px"></el-input>
          </el-form-item>
          <el-form-item label="所属地区" prop="proId" :rules="[{ required: true, message: '所属地区不可为空', trigger: 'blur' }]" v-if="showArea || editType=='add'">
            <ChooseArea ref="chooseArea" v-model="areaValue" @changeArea="changeArea" :selectedLevel="4" style="width:500px" ></ChooseArea>
          </el-form-item>
        </div>
        <div style="text-align:center">
          <el-form-item label="关键字" >
              <el-input v-model="form.keyword" placeholder="请输入关键字" style="width:500px"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]">
            <el-select v-model="form.status" placeholder="请选择" style="width:500px">
              <el-option label="已合作" value="1"></el-option>
              <el-option label="未合作" value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="text-align:center">
          <el-form-item label="编码"  >
              <el-input v-model="form.code" placeholder="请输入编码" style="width:500px"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="type" >
            <el-input v-model="form.schoolPhone" placeholder="请输入联系电话" style="width:500px"></el-input>
          </el-form-item>
        </div>

        <div style="text-align:center">
          <el-form-item label="地址" >
            <el-input v-model="form.address" placeholder="请输入地址"  style="width:500px"></el-input>
          </el-form-item>
          
          <el-form-item label="定位">
            <el-input placeholder="选择定位" v-model="showPointStr" readonly class="input-with-select" style="width:500px">            
              <el-button slot="append" icon="el-icon-search" @click="showSelectedPoint(true)"></el-button>
            </el-input>
          </el-form-item>
        </div>

        <div style="text-align:center">
          <el-form-item label="业务员" prop="salesUserName" >
            <el-input placeholder="选择业务员" v-model="form.salesUserName" readonly class="input-with-select" style="width:500px">            
              <el-button slot="append" icon="el-icon-search" @click="showSelectUser(true,'')"></el-button>
            </el-input>
          </el-form-item>
          
          <el-form-item label="配送员" prop="deliverUserName" :rules="[{ required: true, message: '请选择配送员', trigger: 'blur' }]">
            <el-input placeholder="选择配送员" v-model="form.deliverUserName" readonly class="input-with-select" style="width:500px">            
              <el-button slot="append" icon="el-icon-search" @click="showSelectUser(true,1)"></el-button>
            </el-input>
          </el-form-item>
        </div>

        <div style="text-align:center">
          <el-form-item label="简介">
            <el-input v-model="form.remark" type="textarea" style="width:1090px" placeholder="请输入简介" :autosize="{ minRows: 2, maxRows: 10}"></el-input>
          </el-form-item>
        </div>
        
      </el-collapse-item>
      <el-collapse-item title="年级班级" name="2">
        <div style="text-align:center">
          <el-form-item label="学校类型" prop="schoolTypeId" :rules="[{ required: true, message: '学校类型不能为空', trigger: 'blur' }]">
            <ChooseSchoolType v-model="form.schoolTypeId" @change="schoolTypeChange" style="width:520px"></ChooseSchoolType>
          </el-form-item>

          <el-form-item label="在校人数" prop="studentCount" :rules="[{ required: true, message: '在校人数不能为空', trigger: 'blur' }]">
            <el-input type="number" v-model="form.studentCount" placeholder="请输入在校人数" style="width:500px"></el-input>
          </el-form-item>
          <div style="text-align:center;width:100%;">
            <div style="display:inline-block">
              <el-table :data="baseGradeClassList" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
                <el-table-column label="年级" align="center" prop="name" show-overflow-tooltip width="150"></el-table-column>
                <el-table-column label="班级/人数" align="center" show-overflow-tooltip prop="code" width="940" >
                  <template slot-scope="scope">
                    <el-tag v-for="(classInfo,i) in scope.row.classList" :key="i" v-show="classInfo.isSelected==true" size="mini" :type="'primary'" disable-transitions style="margin-left:2px;">{{classInfo.name+'|'+classInfo.number}}</el-tag>
                  </template>
                  
                </el-table-column>  
                <el-table-column label="编辑" align="center" width="100">
                   <template slot-scope="scope">
                    <el-button @click="showSetClassDialog(scope.row.classList)" size="mini" style="margin-left:10px;" type="primary" icon="el-icon-edit" circle></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>

    <!-- 设置班级弹框 -->
    <el-dialog :title="'选择班级'" :visible.sync="isShowSelectClassDialog" :close-on-click-modal="false" width="420px" >
      <el-row>
        <el-table :data="setClassList" style="width: 100%" height="450" size="mini">
          <el-table-column label="班级" width="180">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isSelected">{{scope.row.name}}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="人数" width="180" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.number" :disabled="!scope.row.isSelected" :min="1" :max="100" label="描述文字" size="mini" style="margin-left:10px;"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <el-col :span="24" style="margin-top:10px;">
          <el-button @click="closeSetClassDialog">关闭</el-button>
          <el-button type="primary" @click="closeSetClassDialog">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <ChooseUser v-if="isShowSelectUser" v-model="isShowSelectUser" @okSelected="okSelectedUser" :isDeliver="isDeliver"  ></ChooseUser>
    <BaiduMap v-if="isShowSelectPoint" :isEdit="true" v-model="isShowSelectPoint" @okSelected="okSelectedPoint" :lng="form.lng" :lat="form.lat"   ></BaiduMap>
  </div>
</template>

<script>
import { addSchoolInfo, updateSchoolInfo,getGradeClassBySchoolTypeId,getSchoolInfo } from '@/api/index.js'
import ChooseArea from '@/components/ChooseArea/index.vue'
import ChooseSchoolType from '@/components/ChooseSchoolType/index.vue'
import ChooseUser from '@/components/ChooseUser/index.vue'
import BaiduMap from '@/components/BDMap/index.vue'
export default {
  name: 'CustomerForm',
  components: { ChooseArea,ChooseSchoolType,ChooseUser,BaiduMap },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      form:{
        id:'',
        name:'',
        keyword:'',
        status:'',
        proId:'',
        proName:'',
        cityId:'',
        cityName:'',
        regId:'',
        regName:'',
        streetId:'',
        streetName:'',
        address:'',
        schoolPhone:'',
        salesUserId:'',
        salesUserName:'',
        deliverUserId:'',
        deliverUserName:'',
        lat:'',
        lng:'',
        code:'',
        introduce:'',
        studentCount:'',
        schoolTypeId:'',
        gradeList:[],
      },
      baseGradeClassList:[],//年级班级列表
      isShowSelectClassDialog:false,//班级设置弹框
      setClassList:[],//当前设置的班级list
      areaValue:[],
      showArea:false,
      isShowSelectUser:false,
      isDeliver:'',
      isShowSelectPoint:false,
      showPointStr:'未设置'
    }
  },
  created() {
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getSchoolInfo();
      this.showPointStr='已设置'
    }
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    okSelectedPoint(item){
      this.form.lng = item.lng;
      this.form.lat = item.lat;
      
      this.showPointStr='已设置';
      //this.showPointStr=item.lng+','+item.lat
    },
    showSelectedPoint(type){
      this.isShowSelectPoint = type;
    },
    showSelectUser(type,isDeliver){
      this.isShowSelectUser = type
      this.isDeliver = isDeliver;
    },
    okSelectedUser(item){
      if(this.isDeliver == 1){
        this.form.deliverUserId = item.id;
        this.form.deliverUserName = item.name;
      }else{
        this.form.salesUserId = item.id;
        this.form.salesUserName = item.name;
      }

    },
    //编辑获取详情
    getSchoolInfo(){
      let param = {id:this.editId};
      
      getSchoolInfo(param).then(res => {
        if (res.code != 200) return
          this.form = res.data;
          //this.areaValue = ['120000', '120100', '120102', '120102003']
          this.areaValue = [this.form.proId,this.form.cityId,this.form.regId,this.form.streetId]
          this.showArea = true;
          this.loadGradeList();
        
      })
    },
    loadGradeList(){
      this.baseGradeClassList = this.form.gradeList.map(grade=>({
          id:grade.id,
          name:grade.name,
          sort:grade.sort,
          originalId:grade.originalId,
          code:grade.code,
          classList: grade.classList.map(classInfo=>({
            id:classInfo.id,
            name:classInfo.name,
            sort:classInfo.sort,
            code:classInfo.code,
            originalId:classInfo.originalId,
            number:classInfo.number,
            isSelected:true
          }))
        }))
    },
    //学校类型切换
    schoolTypeChange(item){
      this.form.schoolTypeId = item.id
      this.getGradeClassBySchoolTypeId();
    },
    //地区选择
    changeArea(selectedData){
      //清空
      if(!selectedData.selectedId){
        this.form.proId = this.form.proName=this.form.cityId=this.form.cityName=this.form.regId=this.form.regName=this.form.streetId=this.form.streetName='';
        return;
      }

      this.form.proId = selectedData.proId;
      this.form.proName=selectedData.proName;
      this.form.cityId=selectedData.cityId;
      this.form.cityName=selectedData.cityName;
      this.form.regId=selectedData.regId;
      this.form.regName=selectedData.regName;
      this.form.streetId=selectedData.streetId;
      this.form.streetName=selectedData.streetName;
    },
    //展现班级设置弹框
    showSetClassDialog(classList){
      this.setClassList = classList;
      this.isShowSelectClassDialog= true;
    },
    //隐藏班级设置弹框
    closeSetClassDialog(){
      this.isShowSelectClassDialog =false
    },
    //根据学校类型加载班级年级数据
    getGradeClassBySchoolTypeId(){
      let param={schoolTypeId:this.form.schoolTypeId};
      getGradeClassBySchoolTypeId(param).then(res => {
        if (res.code != 200) return
        //默认设置班级全部选中，人数50人
        this.baseGradeClassList = res.data.map(grade=>({
          id:grade.id,
          name:grade.name,
          sort:grade.sort,
          originalId:grade.id,
          code:grade.code,
          classList: grade.classList.map(classInfo=>({
            id:classInfo.id,
            name:classInfo.name,
            sort:classInfo.sort,
            code:classInfo.code,
            originalId:classInfo.id,
            number:50,
            isSelected:true
          }))
        }))


      })

    },
    submit() {
     if (!this.$validate('form')) return
      this.form.gradeList = this.baseGradeClassList.map(gradeInfo=>({
          name:gradeInfo.name,
          sort:gradeInfo.sort,
          originalId:gradeInfo.id,
          code:gradeInfo.code,
          classList: gradeInfo.classList.filter(item => item.isSelected==true)

      }))

      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addSchoolInfo(form).then(success)
      } else {
        updateSchoolInfo(form).then(success)
      }
    },
  },
}
</script>
