<template>
  <div style="width:100%">
      
    <SchoolList v-if="editType == 'list'" @changeShowType="changeShowType"></SchoolList>

    <SchoolForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType" @changeShowType="changeShowType"></SchoolForm>
    <SchoolInfo v-if="editType == 'show'" :editId="editId"  @changeShowType="changeShowType"></SchoolInfo>
      
  </div>
</template>

<script>
import SchoolList from './components/SchoolList'
import SchoolForm from './components/SchoolForm'
import SchoolInfo from './components/SchoolInfo'
import {  } from '@/api/index.js'
export default {
  name: 'school',
  components: {  SchoolList,SchoolForm,SchoolInfo},
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>