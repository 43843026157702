<template>
  <div style="width:99%;margin:auto;">
    <div class="form-title">学校详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">基础信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">名称：<span class="col-text">{{form.name}}</span></div>
            <div class="grid-content1">地区：<span class="col-text">{{form.proName}}/{{form.cityName}}/{{form.regName}}/{{form.streetName}}</span></div>
            <div class="grid-content1">关键字：<span class="col-text">{{form.keyword}}</span></div>
            <div class="grid-content1">状态：<span class="col-text">{{form.status=='1'?'已合作':'未合作'}}</span></div>
            <div class="grid-content1">编码：<span class="col-text">{{form.code}}</span></div>
            <div class="grid-content1">联系电话：<span class="col-text">{{form.schoolPhone}}</span></div>
            <div class="grid-content1">地址：<span class="col-text">{{form.address}}</span></div>
            <div class="grid-content1">定位：<span class="col-text blue" @click="showSelectedPoint(true)">查看</span></div>
            <div class="grid-content1">业务员：<span class="col-text">{{form.salesUserName}}</span></div>
            <div class="grid-content1">配送员：<span class="col-text">{{form.deliverUserName}}</span></div>
            <div class="grid-content1">简介：<span class="col-text">{{form.remark}}</span></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">年级班级</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">学校类型：<span class="col-text">{{form.schoolTypeName}}</span></div>
            <div class="grid-content1">在校人数：<span class="col-text">{{form.studentCount}}</span></div>
          </div>
          <div class="product-header">
            <el-row :gutter="20" class="rol-content">
              <el-col :span="2">
                <div class="grid-content">年级</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content">班级/人数</div>
              </el-col>
            </el-row>
          </div>
          <div class="product-content" v-for="(gradeInfo,index) in form.gradeList" :key="index">
            <el-row :gutter="20" class="rol-content">
              <el-col :span="2">
                <div class="grid-content">{{gradeInfo.name}}</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content">
                  <el-tag v-for="(classInfo,i) in gradeInfo.classList" :key="i"  size="mini" :type="'primary'" disable-transitions style="margin-left:2px;">{{classInfo.name+'|'+classInfo.number}}</el-tag>
                </div>
              </el-col>
            </el-row>
            <el-divider class="divder-product"></el-divider>
          </div>
        </div>
      </div>
  

    </div>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>


    <BaiduMap v-if="isShowSelectPoint" v-model="isShowSelectPoint" :isEdit="false" :lng="form.lng" :lat="form.lat"   ></BaiduMap>
  </div>
</template>

<script>
import { getSchoolInfo } from '@/api/index.js'

import BaiduMap from '@/components/BDMap/index.vue'
export default {
  name: 'CustomerForm',
  components: { BaiduMap },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      form:{
        id:'',
        name:'',
        keyword:'',
        status:'',
        proId:'',
        proName:'',
        cityId:'',
        cityName:'',
        regId:'',
        regName:'',
        streetId:'',
        streetName:'',
        address:'',
        schoolPhone:'',
        salesUserId:'',
        salesUserName:'',
        deliverUserId:'',
        deliverUserName:'',
        lat:'',
        lng:'',
        code:'',
        introduce:'',
        studentCount:'',
        schoolTypeId:'',
        gradeList:[],
      },
      isShowSelectPoint: false
    }
  },
  created() {
    this.form.id = this.editId;
    this.getSchoolInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },

    showSelectedPoint(type){
      this.isShowSelectPoint = type;
    },

    //编辑获取详情
    getSchoolInfo(){
      let param = {id:this.editId};
      
      getSchoolInfo(param).then(res => {
        if (res.code != 200) return
          this.form = res.data;
          //this.areaValue = ['120000', '120100', '120102', '120102003']
          this.areaValue = [this.form.proId,this.form.cityId,this.form.regId,this.form.streetId]
          this.showArea = true;        
      })
    },
    
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/info.scss';
</style>
